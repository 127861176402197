/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 16, 2023 */



@font-face {
    font-family: 'gothambold';
    src: url('gotham-bold-webfont.woff2') format('woff2'),
         url('gotham-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gothamlight';
    src: url('gotham-light-webfont.woff2') format('woff2'),
         url('gotham-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gothammedium';
    src: url('gothammedium-webfont.woff2') format('woff2'),
         url('gothammedium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'gothamthin';
    src: url('gotham-thin-webfont.woff2') format('woff2'),
         url('gotham-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}