 @import url(../Gotham-font/stylesheet.css);

 body {
     font-size: 14px;
 }

 #discovery * {
     font-family: 'gothamlight';
     font-size: medium;
 }

 #discovery .bg-none {
     background: none !important;
 }

 #discovery {
     background: #201D21;
     min-height: 100vh;
     height: auto;
   
 }

 #discovery .speedometer-box-card label {
     color: white !important;
 }

 .linkedin {
     display: flex;

 }

 .linkedin a {
     font-weight: 500 !important;
     color: black !important;
 }

 .linkedin svg {
     margin: auto 5px auto auto;
 }

 .linkedin a {
     margin: auto;
 }

 .linkedin:hover a {
     color: white !important;
 }

 #discovery .dropdown-menu>li>a:focus,
 #discovery .dropdown-menu>li>a:hover {
     color: #9D13C5;
     text-decoration: none;
     background-color: rgb(0, 0, 0, 0.0) !important;
 }

 .containerBox {
     padding-inline: 20px;
     max-width: 1480px;
     width: 100%;
     margin: auto;
 }
.tick img{
    vertical-align: top !important;
    display: inline-block;
}
 .banner-box {
     min-height: 100vh;
 }

 .banner-boxx {
     min-height: 26vh;
 }

 #discovery .inner-container {
     background: #201D21;
 }

 .bg-purple {
     background: #9D13C5;
 }

 #discovery .primary-cstm-btn {
     width: 204px;
     height: 50px;
     flex-shrink: 0;
     border-radius: 120px;
     background: #9D13C5;
     border: none !important;
     color: #FFF !important;
     font-size: 16px;
     font-style: normal;
     font-weight: 700;
     line-height: normal;
 }

 #discovery nav {
     padding: 20px 0px;
     z-index: 999;
     border-radius: 0 !important;
 }

 #discovery nav.scrollTop {
     background-color: rgba(0, 0, 0, 0.80);
 }


 #discovery nav::after,
 #discovery nav::before {
     display: flex;
 }

 #discovery .navbar-nav {
     gap: 20px;
 }

 #discovery nav li {
     display: flex;
     align-items: center;
     cursor: pointer;
 }

 #discovery nav li a {
     color: #FFFFFF !important;
     font-size: 18px !important;
     line-height: normal;
     padding: 0;
     font-family: 'gothamlight';
 }

 .dropdown-icon {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 40px;
     height: 40px;
     cursor: pointer;
     margin: 10px auto;
   
 }

 .dropdown-iconn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 10px auto;
  
}

 /* Icon styling */
 .dropdown-icon i {
     font-size: 18px;
     width: 40px;
     margin-top: 10px;
     justify-content: center;
     display: flex;
     padding: 60px;
     height: 40px; 
     transform: scale(1);
     position: absolute;
     transition: ease-in-out .3s;
   
}

.dropdown-iconn i {
    font-size: 18px;
    width: 40px;
    margin-top: 10px;
    justify-content: center;
    display: flex;
    padding: 60px;
    height: 40px; 
    transform: scale(1);
    position: absolute;
    transition: ease-in-out .3s;
  
}
 .toggle-icon{
    margin-top: 20px;
    margin-left: 1px;
    display: block;
    transform: scale(1);
 }

 .toggle-iconn{
    margin-top: 20px;
    margin-left: 11px;
    margin-bottom: 20px;
    display: block;
    transform: scale(1);
 }

 .dropdown-icon:hover,
 .dropdown-icon:hover{
    transform: scale(1.2) !important;
     transition: ease-in .3s;
     background: rgba(157, 19, 197, 0.50) !important;
     border-radius: 100% !important;
     border: 1px solid #fff;
     box-shadow: 0 0 18px rgba(157, 19, 197, 0.5);
 }
 /* Hover effect */

 .dropdown-iconn:hover,
 .dropdown-iconn:hover{
    transform: scale(1.2) !important;
     transition: ease-in .3s;
     background: rgba(157, 19, 197, 0.50) !important;
     border-radius: 100% !important;
     border: 1px solid #fff;
     box-shadow: 0 0 18px rgba(157, 19, 197, 0.5);
 }

 /* Rotate the icon when grid view is active */
 .dropdown-icon i.fa-chevron-up {
     transform: rotate(180deg);
     /* Rotate icon to point up */
 }
 .dropdown-iconn i.fa-chevron-up {
    transform: rotate(180deg);
    /* Rotate icon to point up */
}
 #discovery nav li a:hover {
     color: #9D13C5 !important;
     cursor: pointer;
 }

 #discovery .signin-btn {
     border-radius: 10px;
     -moz-border-radius: 10px;
     -webkit-border-radius: 10px;
     background: #9D13C5;
     font-size: 16px;
     font-family: 'gothambold';
     color: #fff;
     padding: 2px 35px 3px;
 }

 #discovery nav button.navbar-toggler {
     background: #fff;
     margin-left: auto;
 }

 #discovery nav button.navbar-toggler .navbar-toggler-icon {
     height: 24px;
     width: 24px;
 }

 video.video {
     height: 100vh;
     object-fit: cover;
     object-position: right;
 }

 #discovery .video-container .layer {
     position: absolute;
     height: 100%;
     width: 100%;
     top: 0px;
     z-index: 1;
     background: linear-gradient(180deg, rgba(2, 2, 2, 0.00) 70%, rgb(32 29 33) 96%, rgb(32 29 33) 100%);
 }

 #discovery .video-container-content {
     position: absolute;
     z-index: 3 !important;
     bottom: 0px;
     left: 0;
     width: 100%;
     padding: 100px 0px;
 }

 .banner-slogen {
     margin-top: 17px;
 }

 #discovery .video-container-content h1 {
     color: #FFF;
     font-size: 24px;
     font-family: 'gothamlight';
     line-height: 1.5;
 }

 #discovery .video-container-content span {
     font-family: 'gothambold';
     font-size: 24px;
 }

 .page-down-btn {
     transform: scale(0.8);
     padding: 20px;
     width: 50px;
     justify-content: center;
     display: flex;
     height: 50px;
     transition: ease-in-out .3s;
     padding: 0px;
     background: none;
     border: none;
     margin: 10px auto 0;
 }

 .page-down-btn span {
     font-size: 0.1px;
     display: block;
     height: 16px;
     width: 20px;
     color: white;
     background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAARCAYAAAAL4VbbAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABaSURBVHgB3Y7BCcAgEASPVJASUkL6/1iCJVhC0sFlg/sQ0Tt9CQ4sfmYORYCqXlj4X7GgmDSTzIAXS/pBdXm74Gj4L2eD+sSi+42FIuUwJFK+sccVqyC64gwf5JpP4qf/bMoAAAAASUVORK5CYII=);
     background-repeat: no-repeat;
     transform: rotate(90deg);
     background-position: center;
     padding: 20px;
     border-radius: 100%;
     border: 1px solid white;
     margin: auto;
 }

 .page-down-btn:hover {
     transform: scale(1) !important;
     transition: ease-in .3s;
     background: rgba(157, 19, 197, 0.50) !important;
     border-radius: 100% !important;
 }

 .getstarted {
     background: #201323;
     min-height: 368px;
     position: relative;
     z-index: 1;
     color: #FFF;
     font-family: 'gothamthin';
 }

 .objects {
     position: absolute;
     height: 100%;
     width: 100%;
     z-index: 2;
 }

 .GetStartedContent {
     max-width: 650px;
     text-align: center;
 }

 .getstarted h1 {
     font-size: 40px !important;
     font-family: 'gothambold' !important;
     line-height: 1.3;
     color: white !important;
 }

 .getstarted p {
     font-size: 19px;
     line-height: 1.5;
     margin-block: 30px;
 }

 .Semi-Circle {
     position: absolute;
     bottom: 0%;
     left: 0%;
     height: 200px;
     width: 400px;
     border-radius: 200px 200px 0 0;
     background-color: rgba(157, 19, 197, 0.50);
 }

 /* ---------------------------------carousal------------------------------ */
 .sliderBox label,
 .sectionHeading {
     color: #FFF !important;
     font-size: 18px !important;
     font-style: normal !important;
     font-weight: 700 !important;
     line-height: normal !important;
     text-transform: uppercase !important;
     border-left: 3px solid rgb(157, 19, 197) !important;
     position: relative !important;
     padding-left: 10px;
     margin-bottom: 20px;
 }



 .sliderBox img,
 .sectionHeading {
     color: #FFF !important;
     font-size: 13px !important;
     font-style: normal !important;
     font-weight: 700 !important;
     line-height: normal !important;
     /* text-transform: uppercase !important; */
     /* border-left: 3px solid rgb(157, 19, 197) !important; */
     position: relative !important;
     cursor: pointer;
     padding-left: 10px;
     margin-bottom: 20px;
 }

 .Carousel-img-logo {
     position: relative;
 }

 .owl-dots {
     display: none;
 }

 .containerBox label,
 .sectionHeading {
     color: #FFF !important;
     font-size: 18px !important;
     font-style: normal !important;
     font-weight: 700 !important;
     line-height: normal !important;
     text-transform: uppercase !important;
     border-left: 3px solid rgb(157, 19, 197) !important;
     position: relative !important;
     padding-left: 10px;
     margin-bottom: 20px;
 }
 #discovery .item {
     position: relative;
     border-radius: 10px;
     -moz-border-radius: 10px;
     -webkit-border-radius: 10px;
 }

 .owl-item:hover {
     z-index: 999;
     transition: transform .2s;
 }

 .Carousel-img-bg-div {
     position: absolute;
     z-index: 99;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     background: rgb(0 0 0 / 30%);
 }

 .Carousel-img-bg-div img {
     width: auto !important;
 }

 .Carousellayer {
     background: rgba(47, 44, 48, 0.40) !important;
     width: 100%;
     height: 100%;
     position: absolute;
     top: 0;
     z-index: 2;
 }

 .owl-item.active .item {
     display: flex;
     justify-content: center;
     align-items: center;

 }

 .sliderBox {
     margin-bottom: 50px;
     overflow-x: hidden;
 }

 #discovery .owl-nav{
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    bottom: 0;
    margin-top: 0 !important;
    visibility: hidden;
 }

 #discovery .dropdown-icon{
     display: flex;
     justify-content: space-between;
     /* position: relative;
     width: 100%;
     bottom: 0; */
     /* margin-top: 0 !important; */
     visibility: hidden;
 }


 
 #discovery .dropdown-iconn{
    display: flex;
    justify-content: space-between;
    /* position: relative;
    width: 100%;
    bottom: 0; */
    /* margin-top: 0 !important; */
    /* visibility: hidden; */
}
 #discovery .sliderBox:hover .dropdown-icon,
 #discovery .sliderBox:hover .owl-nav,
 #discovery #Core-Accounts.sliderBox .owl-nav {
     visibility: visible;
 }

 #discovery .sliderBox:hover .dropdown-iconn,
 #discovery .sliderBox:hover .owl-nav,
 #discovery #Core-Accounts.sliderBox .owl-nav {
     visibility: visible;
 }



 #discovery .owl-nav button {
     transform: scale(0.8);
     padding: 20px;
     width: 50px;
     justify-content: center;
     display: flex;
     height: 50px;
     transition: ease-in-out .3s;
     position: absolute;
     top: -150px;
 }

 #discovery .owl-nav .owl-prev {
     left: -60px;
     bottom: 60px;
 }

 #discovery .owl-nav .owl-next {
     right: -60px;
     bottom: 60px;
 }

 .owl-prev span {
     font-size: 0.1px;
     display: block;
     height: 16px;
     width: 20px;
     color: transparent;
     background: url("../img/owl-button-icon.png");
     background-repeat: no-repeat;
     transform: rotate(180deg);
     background-position: center;
     padding: 20px;
     border-radius: 100%;
     border: 1px solid transparent;
     margin: auto;
 }

 .owl-next span {
     font-size: 0.1px;
     display: block;
     height: 16px;
     width: 20px;
     color: transparent;
     background: url("../img/owl-button-icon.png");
     background-repeat: no-repeat;
     background-position: center;
     padding: 20px;
     border-radius: 100%;
     border: 1px solid transparent;
     margin: auto;

 }

 .companies-grid {
     display: grid;
     gap: 10px;
     justify-content: center;
     align-items: center;
 }

 @media (max-width: 768px) {
     .companies-grid {
         grid-template-columns: repeat(3, 25px);
         /* 3 items per row on smaller screens */
     }
 }

 @media (max-width: 480px) {
     .companies-grid {
         grid-template-columns: repeat(2, 25px);
         /* 2 items per row on very small screens */
     }
 }

 .owl-nav button:hover,
 .owl-nav button:hover {
     transform: scale(1) !important;
     transition: ease-in .3s;
     background: rgba(157, 19, 197, 0.50) !important;
     border-radius: 100% !important;
 }

 .owl-nav button:hover span {
     border: 1px solid #fff;
 }

 .owl-stage .owl-item {
     cursor: pointer;
 }

 /* ---------------------  */
 /* #discovery .owl-carousel .owl-stage-outer{
    overflow:visible;
} */
 #discovery .item .BoxPreview {
     max-width: 400px;
     z-index: 999;
     height: 250px;
     background: rgba(32, 32, 32, 1);
     display: none;
     position: relative;
     border-radius: 10px;
     -moz-border-radius: 10px;
     -webkit-border-radius: 10px;
     overflow: hidden;
     border: none;
     scale: 1;
     transition: all 1s linear;
     transition-delay: 2s;
 }

 #discovery .item:hover .BoxPreview {
     display: block;
     scale: 1.1;
     transition: all 1s linear;
     transition-delay: 2s;
 }

 #discovery .item .BoxPreview .videoBx {
     position: relative;
 }

 #discovery .item .BoxPreview .videoBx::before {
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     width: 100%;
     background: linear-gradient(180deg, rgba(2, 2, 2, 0) 21.36%, rgba(32, 32, 32, 0.7) 80%, rgba(32, 29, 33, 1) 100%);
 }

 #discovery .item .videoBx {
     width: 100%;
     height: 150px;
 }

 video {
     width: 100%;
     height: 100%;
     object-fit: cover;
 }

 #discovery-footer {
     background: #201D21;
     padding: 50px 0px 0 !important;
     height: 100% !important;
     font-family: 'Roboto', sans-serif !important;
 }

 #discovery-footer p {
     margin: auto;
     color: #FFF;
     font-size: 11px;
     font-style: normal;
     font-weight: 400;
     line-height: 33px;
 }

 #discovery-footer hr {
     width: 100%;
     color: #373439;
     opacity: 1;
 }

 #discovery-footer .get-update {
     max-width: 363px;
     color: #fff;
 }

 #discovery-footer .get-update label {
     margin-bottom: 8px;
 }

 #discovery-footer .get-update p {
     font-size: 14px;
     font-family: 'gothamlight';
     line-height: 1.5;
 }

 #discovery-footer .get-update form {
     margin-block: 15px 25px;
 }

 #discovery-footer input.search {
     color: #505050;
     text-align: left;
     font-size: 14px;
     font-style: normal;
     font-weight: 400;
     line-height: 58px;
     border-radius: 150px;
     border: 1px solid #D7D7D7;
     height: 48px;
     margin-block: 10px 15px;
     padding-inline: 20px;
     background: #FFF;
 }

 #discovery-footer ul {
     list-style: none !important;
     padding-left: 0;
 }

 #discovery-footer label {
     color: #FFF;
     font-size: 14px;
     font-family: 'gothammedium';
     line-height: 1.5;
     margin-bottom: 15px;
 }

 #discovery-footer ul li {
     color: #FFF;
     font-size: 14px;
     font-family: 'gothamlight';
     margin-bottom: 7px;
 }

 #Core-Accounts {
     position: relative;
     z-index: 99;
 }

 .shortbreef-modal {
     border-radius: 22px;
     -moz-border-radius: 22px;
     -webkit-border-radius: 22px;
     border: 2px solid #9D13C5;
     color: #fff;
     font-size: 16px;
 }

 .shortbreef-modal .btn-close {
     opacity: 1;
     filter: invert(1);
     border: 1px solid #000;
     border-radius: 50%;
     -moz-border-radius: 50%;
     -webkit-border-radius: 50%;
 }

 .shortbreef-modal .btn-close:hover {
     filter: grayscale(1);
 }

 .shortbreef-modal h3 {
     font-size: 17px;
     font-family: 'gothammedium' !important;
 }

 .shortbreef-modal p {
     color: #BABABA;
     font-size: 14px;
 }

 .bg-dark {
     background: rgba(32, 29, 33, 1);
 }

 .bg-gradient {
     background: linear-gradient(180deg, rgba(20, 18, 20, 0.00) 0%, #000 54.79%) !important;
 }

 .bg-gray {
     background: #1C1A1F !important;
 }

 .bg-video {
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     z-index: 0;
 }

 .bg-video+* {
     z-index: 1;
 }

 .bg-video::after {
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: linear-gradient(180deg, rgba(20, 18, 20, 0.00) 0%, #000 54.79%);
 }

 .speedometer-box {
     display: flex;
     flex-flow: column;
     gap: 20px;

 }

 ul.speedometer-box li {
     font-size: 12px !important;
 }

 ul.speedometer-box li div {

     height: 134px;
     position: relative;
     width: 100%;

 }

 ul.speedometer-box svg {
     transform: scale(0.4) !important;
     width: 300px;
     height: 300px;
     top: -32px;
     position: absolute;
     left: 80px;
     /* left: 33%; */

 }

 .speedometer-box svg {
     transform: scale(0.4);
 }

 .speedometer-box svg text {
     fill: white !important;
 }

 .speedometer-box-card {
     position: relative;
     height: 100px;
 }

 .speedometer-box-card label {
     font-size: 12px !important;
     margin-bottom: auto;
     margin-top: 11%;
 }

 .speedometer-div {
     width: 100% !important;
     position: relative;
 }

 .speedometer-div div {

     position: absolute;
     left: -75px;
     top: -89px;
     position: absolute;
 }

 .speedometer-box li {
     display: flex;
     flex-flow: row nowrap;
     justify-content: space-between;
     align-items: center;
     padding-inline: 10px;
     padding-top: 20px;
     font-size: 15px !important;
 }

 .speedometer-box li+li {
     border-top: 1px solid #2f2f2f
 }

 .speedometer {
     margin-top: -20px;
 }

 .btn-link {
     font-size: 18px;
     font-family: 'gothammedium' !important;
     color: #000;
     padding: 8px 15px;
     background: #fff;
     text-decoration: none;
 }

 .btn-link:hover,
 .btn-link:active,
 .btn-link:focus-within,
 .btn-link:active:focus-visible {
     background: #9D13C5 !important;
     color: #fff !important;
     text-decoration: none;
     outline: none;
     box-shadow: 0 0 10px #9f13c55a !important;
 }

 .btn-purpal {
     background: #9D13C5 !important;
     color: #fff !important;
 }

 .btn-purpal:hover,
 .btn-purpal:active,
 .btn-purpal:focus-within,
 .btn-purpal:active:focus-visible {
     background: #5c0a72 !important;
     color: #fff !important;
     text-decoration: none;
     outline: none !important;
     box-shadow: 0 0 10px #9f13c55a !important;
 }

 .btn-outline-purple {
     border: 1px solid #9D13C5 !important;
     color: #fff !important;
     padding-inline: 14px;
 }

 .btn-outline-purple:hover,
 .btn-outline-purple:active,
 .btn-outline-purple:focus-within,
 .btn-outline-purple:active:focus-visible {
     background: #5c0a72 !important;
     color: #fff !important;
     text-decoration: none;
     outline: none !important;
     box-shadow: 0 0 10px #9f13c55a !important;
 }

 .btn-white {
     background: #fff !important;
     color: #201323 !important;
 }

 .btn-white:hover,
 .btn-white:active,
 .btn-white:focus-within,
 .btn-white:active:focus-visible {
     background: #5c0a72 !important;
     color: #fff !important;
     text-decoration: none;
     outline: none !important;
     box-shadow: 0 0 10px #9f13c55a !important;
 }

 #discovery .card {
     border-radius: 10px;
     -moz-border-radius: 10px;
     -webkit-border-radius: 10px;
     background: #0C0B0D;
     color: #fff;
     margin-bottom: 20px;
     overflow: hidden;
 }

 #discovery .card h3 {
     font-size: 18px;
     font-family: 'gothammedium';
 }

 #discovery .card .card-header {
     font-size: 18px;
     font-family: 'gothammedium';
     padding: 12px 20px;
 }

 #discovery .card a {
     font-size: 16px;
     color: #fff;
 }

 #discovery .card .card-body {
     padding: 10px 30px;
 }

 #discovery .card .card-body h4 {
     font-family: 'gothammedium';
     font-size: 16px;
     text-transform: capitalize;
     color: #fff;
 }

 #discovery .card .card-body.short-inline-padding {
     padding: 16px 20px;
 }

 .keywod-list {
     display: flex;
     flex-flow: row wrap;
     gap: 10px;
     margin-top: 20px;
 }

 .keywod-list li {
     padding: 6px 15px;
     background: #4E235B;
     border-radius: 20px;
     font-size: 14px;
 }

 .company-breef-table {
     margin-top: 20px;
     border-radius: 10px;
     -moz-border-radius: 10px;
     -webkit-border-radius: 10px;
     overflow: hidden;
 }

 .company-breef-table table tr>* {
     background: #1F1522;
     border: none !important;
     padding: 12px 20px !important;
 }

 .company-breef-table table {
     margin-bottom: 0;
 }

 .company-breef-table table tr th {
     border-right: 1px solid #412C46 !important;
 }

 .company-breef-table table tr:first-child>* {
     border-top: 0;
 }

 .company-breef-table .table-striped>tbody>tr:nth-of-type(odd)>* {
     --bs-table-accent-bg: #0C0B0D;
 }

 .connection-list {
     display: flex;
     flex-flow: row wrap;
 }

 .connection-list li {
     width: 33.33%;
     padding-inline: 10px;
 }

 .connection-list .card {
     border: 1px solid #9D13C5;
     border-radius: 10px;
     -moz-border-radius: 10px;
     -webkit-border-radius: 10px;
     padding: 10px;
     display: flex;
 }

 .delete-btn {
     width: 20px;
     height: 20px;
     align-self: end;
     border: 1px solid #fff;
     padding: 5px;
     border-radius: 50%;
     -moz-border-radius: 50%;
     -webkit-border-radius: 50%;
     cursor: pointer;
 }

 .delete-btn:hover {
     background: #9D13C5;
 }

 .delete-btn img {
     filter: invert(1);
     vertical-align: top;
 }

 .connection-list .card .card-img-top {
     width: 65px;
     height: 65px;
     border-radius: 50%;
     -moz-border-radius: 50%;
     -webkit-border-radius: 50%;
     margin-block: 0 15px;
     margin-inline: auto;
 }

 .connection-list .name {
     text-transform: uppercase;
     font-size: 16px;
     color: #fff;
     line-height: 1;
     font-family: 'gothammedium' !important;
 }

 .degination {
     color: #BF9ACA;
     font-size: 13px;
     text-transform: capitalize;
 }

 .connection-list .card .card-body {
     padding: 10px 0;
 }

 .connection-list .card .company-logo {
     margin-bottom: 20px;
 }

 .connection-list .card .card-footer {
     padding-inline: 0px;
 }

 .connection-list .card .btn {
     font-family: 'gothammedium' !important;
 }

 .modal-dialog {
     padding-inline: 10px;
 }

 .awardInfo {
     display: flex;
     align-items: center;
     gap: 5px;
 }

 .info h3 {
     font-size: 14px !important;
 }

 .award-name {
     padding: 4px 8px;
     background: #2C282E;
     font-size: 12px !important;
 }

 .card-info {
     margin-top: 16px;
 }


 .square {
     background-size: cover !important;
     background-position: center !important;
     display: inline-block;
     width: 350px;
     height: 200px;
     transition: transform 100ms ease-out, border-radius 200ms ease-out;
 }

 .square:hover {
     background: url(https://media.giphy.com/media/lgcUUCXgC8mEo/giphy.gif), url(https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Arrestbygningen_ved_r%C3%A5d-_og_domhuset.jpg/320px-Arrestbygningen_ved_r%C3%A5d-_og_domhuset.jpg), skyblue;
     border-radius: 10px 10px 0 0;
     transform: scale(1.5);
     box-shadow: 0 0 2px #000a;
 }

 .square::after {
     position: relative;
     top: 100px;
     display: block;
     background: #18181818;
     box-shadow: 0 0 2px #000a;
     color: #fff;
     width: 150px;
     height: fit-content;
     padding: 5px;
     box-sizing: border-box;
     opacity: 0;
     border-radius: 0 0 10px 10px;
     transition: opacity 300ms ease-out, border-radius 200ms ease-out;
 }

 .square:hover::after {
     opacity: 1;
 }

 .square.one {
     background: url(https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Arrestbygningen_ved_r%C3%A5d-_og_domhuset.jpg/320px-Arrestbygningen_ved_r%C3%A5d-_og_domhuset.jpg), skyblue;
 }

 .square.one:hover {
     background: url(https://media.giphy.com/media/lgcUUCXgC8mEo/giphy.gif), url(https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Arrestbygningen_ved_r%C3%A5d-_og_domhuset.jpg/320px-Arrestbygningen_ved_r%C3%A5d-_og_domhuset.jpg), skyblue;
 }


 /* -------------------------media querry -------------------------------- */


 @media(max-width:992px) {

     #discovery .navbar-collapse.collapse {
         display: none !important;
     }

     #discovery .navbar-collapse.collapse.in {
         display: block !important;
     }

     .containerBox {
         padding-inline: 40px;
     }

     .navbar .containerBox {
         padding-inline: 30px;
     }

     .navbar-nav {
         margin-top: 20px;
     }

     .footer-logo {
         text-align: center;
     }

     .getstarted h1 {
         font-size: 30px;
     }

     .getstarted p {
         font-size: 18px;
     }

     #discovery .primary-cstm-btn {
         width: fit-content;
         height: auto;
         padding: 14px 40px;
     }

     .objects>* {
         scale: 0.6;
     }

     .objects svg:last-child {
         right: -100px !important;
         left: auto;
         top: -35px !important;
     }

     .objects svg {
         top: -74px;
         position: absolute;
         left: -78px;
     }

     .objects .Semi-Circle {
         left: -80px;
         bottom: -39px;
     }
 }

 @media(min-width:1024px) {
     #discovery .owl-nav .owl-prev {
         left: -30px;
         bottom: 30px;
     }

     #discovery .owl-nav .owl-next {
         right: -30px;
         bottom: 30px;
     }

     .navbar .containerBox {
         padding-inline: 20px;
         display: flex;
         justify-content: space-between;
         align-items: center;
     }

     .containerBox {
         padding-inline: 40px;
     }

     #discovery .navbar-nav {
         gap: 30px;
     }
 }

 @media(min-width:1200px) {
     #discovery .owl-nav .owl-prev {
         left: -60px;
         bottom: 40px;
     }

     #discovery .owl-nav .owl-next {
         right: -60px;
         bottom: 40px;
     }

     .containerBox {
         padding-inline: 20px;
     }

     .navbar .containerBox {
         padding-inline: 10px 0px;
     }

     #discovery .navbar-nav {
         gap: 45px;
     }
 }



 /* -------------------------------------------------------hover-Carousel--------------------------------------------- */
 .d9x .owl-stage-outer {
     padding: 20px 0;
 }

 #Core-Accounts-Carousel-item {
     background: black;
     position: relative !important;
     transition: transform .3s;
     transform: scale(0.9);

 }

 .item:hover .hover-image {
     z-index: 1;
     transform: scale(1.1);
 }

 .item:hover .hover-background {
     z-index: 1;
     transform: scale(1.1);
 }

 #Core-Accounts-Carousel-item:hover {
     transform: scale(1.1);



 }

 .hover-Carousel {
     width: 100%;
     border-radius: 10px;
 }

 .hover-Carousel .hover-Carousel-item {
     width: 100%;
     height: 200px;
     display: flex;
     border-radius: 10px;
     background-color: "#fff";
 }

 .hover-Carousel .hover-Carousel-item img {
     border-radius: 10px;
     background-color: "#fff";
 }

 #Core-Accounts-Carousel-item:hover .blur-layer {
     display: block !important;
     background-color: "#fff";
 }

 #Core-Accounts-Carousel-item:hover .hover-Carousel .hover-Carousel-item img {
     display: none;
     background-color: "#fff";
 }

 .hover-Carousel #hover-Carousel-item-background {
     position: absolute;
     left: 0px;
     top: 0px;
     width: 100% !important;
     height: 100% !important;
     display: none;
     z-index: 1;
     background-position: center;
     background-color: "#fff" !important;
 }

 .hover-Carousel .hover-Carousel-item img {
     width: 100% !important;
     height: 100% !important;
     margin: auto !important;
     z-index: 3;
 }

 #Core-Accounts-Carousel-item:hover #hover-Carousel-item-background {
     background-color: "#fff";
     display: block !important;
     background-size: cover;
     background-color: "#fff" !important;
     background-repeat: no-repeat;
     border-radius: 10px;
 }

 .blur-layer {
     display: none;
     border-radius: 10px;
     position: absolute;
     z-index: 2;
     left: 0px;
     top: 0px;
     height: 100%;
     width: 100%;
     background: rgba(47, 44, 48, 0.50);
 }

 /* ------------------------------------------------------hover-Carousel end------------------------------------------- */

 .openModal-company-logo {
     width: auto !important;
     height: auto;
     max-height: 142px !important;
     max-width: 142px !important;
     flex-shrink: 0 !important;
 }

 .bg-none {
     background: none !important;
 }

 @media(max-width:767px) {
     ul.speedometer-box svg {

         left: 0% !important;
     }
 }
 @media(max-width:787px) {
    ul.speedometer-box svg {
     left: 0px !important;
     margin-left: 0% !important;
    }
}